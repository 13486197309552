import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();

export async function getProductionReport(cookies, inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/production_report?plant_ids=${inputs.plant_ids}&customer_ids=${inputs.customer_ids}&consignee_ids=${inputs.consignee_ids}&sales_rep_ids=${inputs.sales_rep_ids}&vehicle_ids=${inputs.vehicle_ids}&product_ids=${inputs.product_ids}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`)
  .then(response => response.data);
}

export async function getProductionReportCSV(cookies, inputs) {
  return getApiClient(cookies).get(`${apiBaseUrl}/production_report`, {
    params: {
      plant_ids: inputs.plant_ids,
      customer_ids: inputs.customer_ids,
      consignee_ids: inputs.consignee_ids,
      sales_rep_ids: inputs.sales_rep_ids,
      vehicle_ids: inputs.vehicle_ids,
      product_ids: inputs.product_ids,
      from_date: inputs.from_date,
      upto_date: inputs.upto_date,
      download_option: "csv"
    },
    responseType: 'blob' // Ensure the response is handled as a blob
  });
}

export async function getDispatchReport(cookies,inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/dispatch_report?plant_ids=${inputs.plant_ids}&customer_ids=${inputs.customer_ids}&consignee_ids=${inputs.consignee_ids}&sales_rep_ids=${inputs.sales_rep_ids}&vehicle_ids=${inputs.vehicle_ids}&product_ids=${inputs.product_ids}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}&report_format=${inputs.report_format}`)
  .then(response => response.data);
}

export async function getDispatchReportCSV(cookies, inputs) {
  return getApiClient(cookies).get(`${apiBaseUrl}/dispatch_report`, {
    params: {
      plant_ids: inputs.plant_ids,
      customer_ids: inputs.customer_ids,
      consignee_ids: inputs.consignee_ids,
      sales_rep_ids: inputs.sales_rep_ids,
      vehicle_ids: inputs.vehicle_ids,
      product_ids: inputs.product_ids,
      from_date: inputs.from_date,
      upto_date: inputs.upto_date,
      report_format: inputs.report_format,
      download_option: "csv"
    },
    responseType: 'blob' // Ensure the response is handled as a blob
  });
}

export async function getWorkScheduleReport(cookies,inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedule_report?plant_id=${inputs.plant_id}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`)
  .then(response => response.data);
}

export async function getWorkScheduleReportCSV(cookies, inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedule_report`, {
    params: {
      plant_id: inputs.plant_id,
      from_date: inputs.from_date,
      upto_date: inputs.upto_date,
      download_option: "csv"
    },
    responseType: 'blob' // Ensure the response is handled as a blob
  });
}

export async function getUserReport(cookies,inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/user_report?user_ids=${inputs.user_ids}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`)
  .then(response => response.data);
}

export async function getUserReportCSV(cookies, inputs) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/user_report`, {
    params: {
      user_ids: inputs.user_ids,
      from_date: inputs.from_date,
      upto_date: inputs.upto_date,
      download_option: "csv"
    },
    responseType: 'blob' // Ensure the response is handled as a blob
  });
}

export async function getDcForTally(cookies,inputs) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/dc_for_tally?plant_id=${inputs.plant_id}&from_date=${inputs.from_date}&upto_date=${inputs.upto_date}`)
  .then(response => response.data);
}
export async function getCustomerDataReport(cookies) {

    // setTimeout(5000, () => {
    //   console.log('Waiting....');
      
    // });
    return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
      'Authorization': `Token ${cookies['myToken']}`
    }})
    .then(response => response.json());
    
              // .then((data) => {
              //   return data;
              // });
}

export async function getEquipmentReport(cookies) {
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
}

export async function getGoodsReceiptNote(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  
  return fetch(`${apiBaseUrl}/accounting_master/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}

export async function getAgeWiseReport(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  
  return fetch(`${apiBaseUrl}/accounting_master/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}

export async function getSalesPostingReport(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}

export async function getAccountReceivableReport(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}

export async function getGSTReport(cookies) {
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
}

export async function getReceiptReport(cookies) {
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
}


export async function getAgeWiseSummary(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  
  return fetch(`${apiBaseUrl}/entity_salesrep/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}
export async function getLedgerAccountReport(cookies) {

  // setTimeout(5000, () => {
  //   console.log('Waiting....');
    
  // });
  return fetch(`${apiBaseUrl}/accounting_master/`,{method: 'GET',headers: {
    'Authorization': `Token ${cookies['myToken']}`
  }})
  .then(response => response.json());
  
            // .then((data) => {
            //   return data;
            // });
}